import React from "react"

import Layout from '../templates/Page';
import SEO from "../components/seo"

const NotFound = () => (
  <Layout>
  <div className="container">
  <div className="text-center mt-3 mb-3">
    <SEO title="404: Not found" />
    <h1>404</h1>
    <h1>Page Not Found</h1>
    </div>
    </div>
  </Layout>
)

export default NotFound
